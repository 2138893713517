(function($) {
  /**
    * protected flag used to make sure the module is not initialized twice
  */
  var _initialized = false;
  $(document).on('spp_inventory_status_sort', function() {
    if (this.loaded || !page_data || !page_data['catalog-spp'] || !page_data['catalog-spp'].products) {
      return null;
    }
    this.loaded = true;
    var $product = page_data['catalog-spp'].products[0];

    // Initialize app code to render.
    initalizeStoreCheck($product.defaultSku);
  });

  // Helper function to init store check.
  function initalizeStoreCheck(skuData) {
    if (_initialized) {
      return true;
    }
    var prodData = page_data['catalog-spp'].products[0];
    var product = { data: {} };

    $.extend(product.data, prodData, skuData);
    // Load dependency library.
    $(document).trigger('bopis:init', {
      skuId: product.data.SKU_BASE_ID,
      product: product.data
    });
    if (Drupal.settings.globals_variables.enable_prod_store_check) {
      $(document).trigger('invis:init', {
        skuId: product.data.SKU_BASE_ID,
        product: product.data
      });
    }
    _initialized = true;
  }
})(jQuery);
